import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import "./stream.css";

const Stream = () => {
  const [movies, setMovies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTrailer, setSelectedTrailer] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();
  const playerRef = useRef(null);

  useEffect(() => {
    axios
      .get("https://showzsbackend.smartvaathi.com/api/movies/latest")
      .then((response) => {
        let responseData = response.data;
        if (!Array.isArray(responseData)) {
          responseData = [responseData];
        }
        const sortedMovies = responseData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 2);
        setMovies(sortedMovies);
      })
      .catch((error) => {
        console.error("There was an error fetching the movies!", error);
      });
  }, []);

  const handleWatchTrailer = (trailerUrl) => {
    if (trailerUrl) {
      setSelectedTrailer(trailerUrl);
      setShowModal(true);
      setTimeout(() => enterFullScreen(), 100); // Enter fullscreen after modal opens
    } else {
      console.error("No valid trailer URL found:", trailerUrl);
    }
  };

  const handleCloseModal = () => {
    exitFullScreen(); // Exit fullscreen if modal is closed
    setShowModal(false);
    setSelectedTrailer("");
  };

  const enterFullScreen = () => {
    if (playerRef.current && playerRef.current.wrapper) {
      const videoElement = playerRef.current.wrapper;
      setIsFullscreen(true); // Set fullscreen state to true
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        // Firefox
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        // IE/Edge
        videoElement.msRequestFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setIsFullscreen(false); // Reset fullscreen state
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        // Exit fullscreen if user presses ESC or exits fullscreen
        setIsFullscreen(false);
      } else {
        setIsFullscreen(true);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const handleBuyTickets = () => {
    navigate("/Movie");
  };

  return (
    <section className="stream">
      <div className="stream-container">
        <hr />
        <h1>Now Streaming</h1>
        {movies.length > 0 ? (
          <div
            className={`movie-container ${
              movies.length === 1 ? "single-movie" : ""
            }`}
          >
            {movies.map((movie) => (
              <div className="movie" key={movie.id}>
                {movie.picture && <img src={movie.picture} alt={movie.title} />}
                <h2>{movie.title}</h2>
                <p>Duration : {movie.duration} min</p>
                <p>Price : {movie.price}</p>
                <div className="buttons">
                  <button
                    className="watch-trailer"
                    onClick={() => handleWatchTrailer(movie.trailer)}
                  >
                    Watch Trailer
                  </button>
                  <button
                    className="buy-tickets"
                    onClick={() => handleBuyTickets(movie)}
                  >
                    Buy Tickets
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No movies available at the moment.</p>
        )}
        <hr />
      </div>

      {showModal && selectedTrailer && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>
              &times;
            </span>
            <ReactPlayer
              ref={playerRef}
              url={selectedTrailer}
              playing
              controls
              className={`trailer-video ${isFullscreen ? "fullscreen" : ""}`}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Stream;
