import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Upload,
  Select,
  message,
  Modal,
  Spin,
  Progress,
} from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const LiveEventForm = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [carouselList, setCarouselFileList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [progressModalVisible, setProgressModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailForm] = Form.useForm();

  const handleSubmit = async (values) => {
    if (fileList.length === 0) {
      message.error("Please upload a cover image for the event.");
      return;
    }
    if (carouselList.length === 0) {
      message.error("Please upload carousel images for the event.");
      return;
    }
    setModalVisible(true);
  };

  const handleModalSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      // Check if the email is already used
      const checkEmailResponse = await axios.post(
        "https://showzsbackend.smartvaathi.com/api/check-email",
        {
          email: values.email,
        }
      );

      if (checkEmailResponse.data.exists) {
        message.error(
          "The email is already used. Please use a different email."
        );
        setIsSubmitting(false);
        return;
      }

      try {
        const userResponse = await axios.post(
          "https://showzsbackend.smartvaathi.com/api/add-users",
          {
            email: values.email,
            password: values.password,
            password_confirmation: values.password_confirmation,
            full_name: values.fullName,
            phone_number: values.phoneNumber,
            user_type: 4,
          }
        );

        if (userResponse.status === 201) {
          const eventValues = form.getFieldsValue();
          const formData = new FormData();
          formData.append("title", eventValues.title);
          formData.append("description", eventValues.description);
          formData.append("date", eventValues.date.format("YYYY-MM-DD"));
          formData.append("time", eventValues.time.format("HH:mm"));
          formData.append("ticketPrice", eventValues.ticketPrice);
          formData.append("category", eventValues.category);
          formData.append("streamLink", eventValues.streamLink);

          if (fileList.length > 0) {
            const coverFile = fileList[0].originFileObj;

            if (!coverFile.type.startsWith("image/")) {
              message.error("Please upload only image files.");
              setIsSubmitting(false);
              return;
            }

            try {
              setProgressModalVisible(true);
              setProgress(0);

              const response = await axios.get(
                "https://showzsbackend.smartvaathi.com/api/s3-CoverImages",
                {
                  params: {
                    file_name: coverFile.name,
                    file_type: coverFile.type,
                  },
                }
              );

              const signedUrl = response.data.url;

              await axios.put(signedUrl, coverFile, {
                headers: {
                  "Content-Type": coverFile.type,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percentCompleted);
                },
              });

              const coverUrl = signedUrl.split("?")[0];
              formData.append("coverImage", coverUrl);
            } catch (error) {
              message.error("Failed to upload cover image.");
              setProgressModalVisible(false);
              return;
            }
          }

          if (carouselList.length > 0) {
            const carouselImage = carouselList[0].originFileObj;

            if (!carouselImage.type.startsWith("image/")) {
              message.error("Please upload only image files.");
              return;
            }

            try {
              setProgressModalVisible(true);
              setProgress(0);

              const carouselImageResponse = await axios.get(
                "https://showzsbackend.smartvaathi.com/api/s3-CoverImages",
                {
                  params: {
                    file_name: carouselImage.name,
                    file_type: carouselImage.type,
                  },
                }
              );

              const signedUrl = carouselImageResponse.data.url;

              await axios.put(signedUrl, carouselImage, {
                headers: {
                  "Content-Type": carouselImage.type,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percentCompleted);
                },
              });

              const carouselImageUrl = signedUrl.split("?")[0];
              formData.append("carousel_images", carouselImageUrl);
            } catch (error) {
              message.error("Failed to upload carousel images.");
              setProgressModalVisible(false);
              return;
            }
          }

          try {
            await axios.post(
              "https://showzsbackend.smartvaathi.com/api/live-events",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percentCompleted);
                },
              }
            );

            message.success("Live event created successfully");
            form.resetFields();
            emailForm.resetFields();
            setFileList([]);
            setCarouselFileList([]);
            setModalVisible(false);
            setProgressModalVisible(false);
          } catch (error) {
            message.error("Failed to create live event.");
            console.error("Error creating event:", error);
            setProgressModalVisible(false);
          }
        }
      } catch (error) {
        console.error("Error adding user:", error);
        message.error(
          "Failed to add user. Please check the credentials and try again."
        );
      } finally {
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error checking email:", error);
      message.error("Failed to verify email. Please try again.");
    }
  };

  const handleUpload = ({ fileList }) => {
    const isImage = fileList.every((file) => file.type.startsWith("image/"));
    if (isImage) {
      setFileList(fileList);
    } else {
      message.error("You can only upload image files for cover!");
      setFileList([]);
    }
  };

  const handleCarouselUpload = ({ fileList }) => {
    const isImage = fileList.every((file) => file.type.startsWith("image/"));
    if (isImage) {
      setCarouselFileList(fileList);
    } else {
      message.error("You can only upload image files for carousel!");
      setCarouselFileList([]);
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Event Title"
          rules={[{ required: true, message: "Please enter the event title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Event Description"
          rules={[
            { required: true, message: "Please enter the event description" },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="date"
          label="Event Date"
          rules={[{ required: true, message: "Please select the event date" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="time"
          label="Event Time"
          rules={[{ required: true, message: "Please select the event time" }]}
        >
          <TimePicker />
        </Form.Item>
        <Form.Item
          name="ticketPrice"
          label="Ticket Price"
          rules={[{ required: true, message: "Please enter the ticket price" }]}
        >
          <Input type="number" min={0} step={0.01} />
        </Form.Item>
        <Form.Item
          name="carousel_images"
          label="Upload Carousel Images"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
        >
          <Upload
            name="carousel"
            listType="picture"
            beforeUpload={() => false}
            onChange={handleCarouselUpload}
            multiple
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="coverImage"
          label="Upload Cover Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
        >
          <Upload
            name="cover"
            listType="picture"
            beforeUpload={() => false}
            onChange={handleUpload}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="category"
          label="Event Category"
          rules={[
            { required: true, message: "Please select an event category" },
          ]}
        >
          <Select>
            <Option value="Music">Music</Option>
            <Option value="Sports">Sports</Option>
            <Option value="Technology">Technology</Option>
            <Option value="Education">Education</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="streamLink"
          label="Stream Link"
          rules={[
            { required: true, message: "Please enter the stream link" },
            { type: "url", message: "Please enter a valid URL" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <Spin indicator={<LoadingOutlined />} />
            ) : (
              "Create Event"
            )}
          </Button>
        </Form.Item>
      </Form>

      {/* Modal for Email */}
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form form={emailForm} layout="vertical" onFinish={handleModalSubmit}>
          <h2>Access For Content Owner</h2>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please enter your password" },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={[{ required: true, message: "Please enter your full name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter your phone number" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                "Submit"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Progress Modal */}
      <Modal
        visible={progressModalVisible}
        onCancel={() => setProgressModalVisible(false)}
        footer={null}
        className="progress-modal"
        closable={false}
        maskClosable={false}
      >
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 48, color: "#1890ff", marginBottom: "20px" }}
              spin
            />
          }
        />
        <h3>Uploading files... Please wait</h3>
        <Progress percent={progress} />
      </Modal>
    </>
  );
};

export default LiveEventForm;
