import React, { useEffect, useState } from "react";
import axios from "axios";
import ticket from "../../assest/ticket new.png";
import { useNavigate } from "react-router-dom";
import "./EventDetails.css";

const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there is a selected event in sessionStorage
    const selectedEvent = sessionStorage.getItem("selectedEvent");

    if (selectedEvent) {
      // If an event is found in sessionStorage, use it
      setEvent(JSON.parse(selectedEvent));
    } else {
      // If no event is found in sessionStorage, fetch the latest event
      axios
        .get(
          "https://showzsbackend.smartvaathi.com/api/live-events/showLastEvent"
        )
        .then((response) => {
          console.log("Event data:", response.data);
          // Assuming the API returns an array of events
          const eventData = Array.isArray(response.data)
            ? response.data[0]
            : response.data;
          setEvent(eventData);
        })
        .catch((error) => {
          console.error("Failed to fetch event data:", error);
        });
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBuyNow = () => {
    if (event) {
      localStorage.setItem("selectedItem", JSON.stringify(event));
      navigate("/checkout", { state: { event } }); // Navigate to checkout page with event data
    }
  };

  return (
    <section className="event-details">
      {event ? (
        <>
          <div className="event-info">
            <h1>{event.title}</h1>
            <div className="event-rating">
              <p>{`Category: ${event.category}`}</p>
              <p>{`Ticket Price: ${event.ticketPrice}`}</p>
              <p>{`Date: ${event.date}`}</p>
            </div>
            <div className="event-actions">
              <div className="buy-now" onClick={handleBuyNow}>
                <img src={ticket} alt="Ticket" className="ticketImg" />
              </div>
            </div>
          </div>
          <div className="event-poster">
            <div className="poster-container">
              {event.coverImage && (
                <img src={event.coverImage} alt={event.title} />
              )}
              <div className="event-details-overlay">
                <h2>{`${event.title} - The Event`}</h2>
                <p>{`Category: ${event.category}`}</p>
                <p>{`Ticket Price: ${event.ticketPrice}`}</p>
                <p>{`Date: ${event.date}`}</p>
              </div>
            </div>
          </div>

          {showModal && event.trailer && (
            <div className="modal-overlay">
              <div className="modal-content">
                <span className="close-button" onClick={handleCloseModal}>
                  &times;
                </span>
                <video controls src={event.trailer} alt="Event Trailer" />
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No event details available at the moment.</p>
      )}
    </section>
  );
};

export default EventDetails;
