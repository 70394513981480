import React from 'react';
import './footer.css';
import logo from '../../assest/logo.png';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';

// Import the App Store and Google Play image (assuming it's saved as 'appstore.png')
// import AppStore from '../../assest/app.png';
import GooglePlay from '../../assest/android.png';

const Footer = () => {
  return (
    <div className="footer section_padding">
      <div className="footer-heading">
        <h1 className="gradient_text">Do you want to step in to the theater before others</h1>
      </div>

      <div className="footer-links">
        <div className="footer-link_logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="footer-link_div">
          <h4>Links</h4>
          <a href="https://globalmeshsolutions.com"><p>Global Mesh Solutions (Pvt) Ltd</p></a>
        </div>

        <div className="footer-link_div">
          <h4>Company</h4>
          <p><Link to="/TermsAndConditions">Terms & Conditions</Link></p>
          <p><Link to="/PrivacyPolicy">Privacy Policy</Link></p>
          <p><Link to="/RefundPolicy">Refund Policy</Link></p>
          <p><Link to="/Contactus">Contact</Link></p>
        </div>

        <div className="footer-link_div">
          <h4>Get in touch</h4>
          <p>Info@Globalmeshsolutions.com</p>
          <p>(+94)76-5299400</p>
          <p>Global Mesh Solutions (Pvt) Ltd. <br /> No- 7B, Floor 25 1/2, Ocean Tower <br /> Building, Station Road, <br />Colombo 04, <br />Srilanka 00400.</p>
        </div>
      </div>

      <div className="footer-divider"></div>
      <div className="footer-copyright">
        <div className="footer-social-icons">
          <a href="https://www.facebook.com/profile.php?id=61566882961308" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.tiktok.com/@showzs8?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
        {/* Add the App Store and Google Play buttons */}
        <div className="footer-app-download">
          {/* <img src={AppStore} alt="App Store" /> */}
          <img src={GooglePlay} alt="Google Play" />
        </div>
        <p>Copyright © 2024 Global Mesh. All rights reserved. Powered by eSupport Technologies Pvt. Ltd</p>
      </div>
    </div>
  );
};

export default Footer;
