import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./events.css";

const Events = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://showzsbackend.smartvaathi.com/api/live-events/showEvent")
      .then((response) => {
        console.log("API response:", response.data);
        let responseData = response.data;
        if (!Array.isArray(responseData)) {
          responseData = [responseData];
        }

        const sortedEvents = responseData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);

        setEvents(sortedEvents);
      })
      .catch((error) => {
        console.error("There was an error fetching the events!", error);
      });
  }, []);

  const handleWatchNowClick = () => {
    navigate("/Event");
  };

  return (
    <section className="live-events">
      <div className="live-events-container">
        <h1>Live Events</h1>
        <div className="event-container">
          {events.length > 0 ? (
            events.map((event) => (
              <div
                className="event animate_animated animate_fadeInUp"
                key={event.id}
              >
                <img src={event.coverImage} alt={event.title} />
                <div className="movie-info">
                  <h2>{event.title}</h2>
                  <p>{event.description}</p>
                  <p>Category: {event.category}</p>
                  <p>Ticket Price: {event.ticketPrice}</p>
                </div>
                <button
                  className="watch-now"
                  onClick={() => handleWatchNowClick(event)}
                >
                  Buy Tickets
                </button>
              </div>
            ))
          ) : (
            <p>No events available at the moment.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Events;
